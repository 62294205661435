/*
Theme Name: Giglist V2.0
Theme URI: http://giglist.com.au
Author: Luke Cleland
Author URI: https://lukecleland.com
Description: Gigs in a List
Version: 1.0
Text Domain: giglist
*/

@import url("https://fonts.googleapis.com/css?family=Merriweather&display=swap");

// #root {
//     height: 100%;
// }

// html {
//     height: 100%;
//     width: 100%;
// }

body {
    /* font-family: "Merriweather", serif; */
    height: 100%;
    width: 100%;
    /* rotate: 0.1deg; */
    background: rgba(0, 0, 0, 0.98);
}

.advert {
    padding: 20px 0 20px 0px !important;
}

.search_event {
    width: 300px;
}

.advert img {
    width: 95%;
}

.sidebar {
    border-left: 1px solid #666;
}

.sidebar.icon {
    top: 0;
}

.ui.grid > * {
    padding-left: 0 !important;
}

.gm-style-iw.gm-style-iw-c .gm-style-iw-d .event-wrapper {
    background: #000 !important;
    padding: 10px;
    margin-bottom: 0;
    list-style-type: none !important;
}

.gm-style-iw.gm-style-iw-c .gm-style-iw-d .event-wrapper::marker {
    display: none !important;
}

.side-scroll {
    width: 100%;
    /* height:100%; */
    float: left;
    margin-top: -10px;
    padding-top: 5px;
}
.email {
    cursor: pointer;
}
.email-form {
    display: none;
}

@font-face {
    font-family: "carbontyperegular";
    src: local("carbontyperegular"),
        url(./font/carbon_2-webfont1.woff) format("woff");
}

.float-right {
    float: right;
}
.copy {
    font-family: "Merriweather", serif;
    margin-right: 4px;
}
.giglist-copy {
    margin-right: 20px;
}
.button {
    font-family: "Merriweather", serif !important;
}

.date > i {
    position: absolute;
    right: 14px;
    top: 2px;
}

.day {
    padding-top: 15px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.date {
    font-size: 20px;
    font-family: "carbontyperegular";
    margin-right: 10px;
    background: white;
    color: black;
    padding: 7px 0 5px 7px;
    cursor: pointer;
    line-height: 1.2;
    height: 35px;
    color: black;
    margin-top: -10px;
}

.date span {
    margin-top: 5px;
}

.header.white {
    color: #fff !important;
}
.sidebar {
    padding: 20px;
    background-color: black !important;
}
.header-links {
    float: right;
    margin-top: 5px;
    font-style: normal;
    padding-right: 20px;
}
.header-link {
    font-weight: 100;
    font-size: 14px;
    padding-right: 20px;
    text-decoration: none;
    width: 220px;
    display: inline;
    font-family: "carbontyperegular";
}
.carbon {
    font-family: "carbontyperegular" !important;
}
.search-input input {
    padding: 5px;
    display: inline;
    font-weight: 100;
    font-size: 14px;
    color: white;
    border: none;
    background-color: #1d1e23;
    font-family: "carbontyperegular";
    text-align: right;
    cursor: pointer;
    outline-width: 0;
    outline: none;
}
.search.link.icon {
    color: white !important;
    opacity: 1 !important;
}

.location-icon {
    margin-right: 10px;
}

#input {
    font-family: "carbontyperegular";
    width: 110px;
}
#input:focus {
    width: 180px;
}
::-webkit-input-placeholder {
    /* Edge */
    color: white !important;
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white !important;
}
::placeholder {
    color: white !important;
}
.event-venue {
    font-size: 13px;
}
.address,
.suburb {
    font-size: 13px;
}
html,
body {
    margin: 0;
    padding: 0;
}

.ui.page.grid {
    margin: 0;
}

.date.dark {
    color: white;
    background: black;
}

h3 {
    text-transform: uppercase !important;
}
header {
    display: block;
    position: relative;
    padding: 10px !important;
    text-align: center;
    height: 70px;
    padding: 10px 0 0 0 !important;
    background-color: #1d1e23;
    padding: 10px;
    min-height: 70px;
    /* text-align: center;
     */
}
header a {
    color: white;
}
/* header a img {
     width:300px;
     height:auto;
     padding:10px 0 0 10px;
} */
header h1 {
    grid-area: area2;
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    margin: 0;
}
header div {
    grid-area: area3;
    font-size: 1rem;
    /* text-align: right;
     */
    padding-bottom: 0.5rem;
    font-style: oblique;
    /* text-align: center;
     */
}

main section {
    overflow: auto;
    /* width: calc(100vw-0px) !important;
     */
    -webkit-column-break-inside: avoid;
    break-inside: avoid-column;
    /* margin: 2vh 0;
     */
    /* column-rule: 2px dashed #ccc; */
    column-count: 1;
    column-gap: 0px;
    column-fill: auto;
    /* background-size: calc(100vw/4.05) 100%; */
    // background-color: rgba(0, 0, 0, 0.95);
}
main section h1 {
    margin: 0 0 -0.75rem 0;
    text-decoration: underline;
}
main section p::first-letter {
    font-size: 2rem;
    letter-spacing: 0.1rem;
}
main section p {
    text-align: justify;
}
.footer {
    text-align: left;
    /* padding: 0.5rem 0;
     */
    font-size: 1rem;
    line-height: 2.4rem;
    padding-left: 10px;
    border-top-left-radius: 20px;
    display: none;
}
.footer .footer-outer {
    margin: 0 auto;
    padding-top: 5px;
    margin-left: 20px;
}
.footer .footer-outer a {
    color: white;
    font-family: "carbontyperegular";
}
.footer .footer-outer a:not(:last-child) {
    margin-right: 0px;
}
.footer {
    position: absolute;
    bottom: 0;
    background: #000;
    height: 45px;
    width: 200px;
    position: absolute;
    right: 0;
}
.mobile-view {
    display: none !important;
}
.slider-menu {
    position: absolute;
    margin-top: -400px;
    background: black;
    height: 400px;
    width: 100%;
}
.ui.inverted.menu {
    background: #000 !important;
}
.date-tab {
    width: 20px;
    display: none;
}
.tab-text {
    font-size: 16px;
    margin-top: 32px;
    width: 80px;
    height: 15px;
    padding-top: 30px;
    margin-bottom: -20px;
}

li {
    padding: 10px;
    /* background: rgba(0, 0, 0, 0.1);
    */
    margin-bottom: 5px;
    white-space: no-wrap;
    position: relative;
}

.date-tab {
    height: 100%;
    width: 30px;
    position: absolute;
    z-index: 999;
    background-color: #000;
}

.white {
    color: #fff;
}
li span {
    float: right;
    margin-top: -20px;
}
li a {
    display: block;
}
/* li.item {
    list-style:unset;
    padding:5px;
}
*/
ul {
    list-style: none !important;
    padding-inline-start: 10px;
    background-image: url(./assets/column_bg5.png);
    background-position: 0px 0;
}

ol#list {
    margin-left: 10px;
    padding: 10px;
}
i.icon.hide-sidebar {
    color: white !important;
    position: absolute !important;
    right: 20px;
    top: 22px;
    cursor: pointer;
}

.main-logo {
    margin-top: 8px;
    margin-left: -5px;
    padding: 5px;
    width: 150px;
}

header ul {
    width: 100%;
}
/* header menu item {
    min-wdth: 100px !important;
} */
.nav-item {
    width: 500px;
}
.ui.inverted.menu {
    background-color: white;
    border: none;
    font-family: carbontyperegular;
    font-size: 16px;
    margin-bottom: 0;
    height: 26px;
}
.ui.vertical.menu {
    margin-top: 0px !important;
    width: 100%;
    display: none;
    margin-right: 15px !important;
}
.ui.vertical.navbar.menu {
    padding-top: 55px !important;
}
.item {
    height: 100%;
}

.bounce {
    display: inline-block;
    position: relative;
    -moz-animation: bounce 0.5s infinite linear;
    -o-animation: bounce 0.5s infinite linear;
    -webkit-animation: bounce 0.5s infinite linear;
    animation: bounce 0.5s infinite linear;
}
.item i {
    margin-left: 10px;
}

.item.small {
    font-size: 14px;
    margin-left: 0px;
}
.hidden {
    display: none;
}
.link.icon {
    z-index: 999;
}
/* *{
    margin: 0;
    padding: 0;
}
*/

.padding-reset {
    padding: 0px !important;
}
/* .ui.message{
    padding: 40px !important;
}
*/

.ui.page.grid {
    padding: 0;
    /* position:relative; */
    height: 80vh;
}

.row {
    padding: 0 !important;
    margin: 0 !important;
}
.full-width {
    width: 100% !important;
}

.search-form {
    border: 3px solid #555;
    border-radius: 10px;
    position: absolute !important;
    padding: 45px 45px 35px 35px !important;
    background: white;
    right: 10px;
    top: 55px;
    z-index: 999;
}
.pad {
    margin-left: 10px !important;
}

::placeholder {
    color: grey;
}

.mobile.only.row > .ui.inverted.menu.navbar > a {
    width: 200px;
    height: 50px;
    display: block;
}

.clear-search {
    position: absolute;
    right: 5px;
    top: 70px;
}

.no-gigs {
    margin-left: 20px;
    font-family: "carbontyperegular";
    font-size: 20px;
}

.ui.link.cards.shop {
    margin: 5px 0 60px 20px;
}

.ui.container.submission {
    position: relative;
    display: block;
}

@media only screen and (max-width: 767px) {
    ul {
        background-image: none !important;
    }

    .sticky {
        position: fixed;
        top: 60px;
        width: 95%;
        z-index: 98;
    }

    main section {
        padding-top: 30px !important;
    }

    .ui.container.submission {
        padding-top: 70px;
    }

    .ui.link.cards.shop {
        margin: 60px 8px 8px 8px;
    }

    .clear-search {
        position: relative;
        top: 0;
        right: 0;
    }

    .clear-search input {
        width: 100%;
    }

    .search-form-mobile {
        margin-top: 20px;
    }

    .search_event {
        width: 100%;
        min-width: 100%;
    }

    /* .ui.menu .item {
        height: 46px;
    } */

    .footer {
        display: none;
    }

    /* .advert img {
        width: 100%;
    } */

    /* .side-scroll {
        margin-top: 70px;
    } */

    .ui.grid > * {
        padding-right: 0;
        margin-top: 55px;
    }

    .ui.grid {
        margin: 0;
    }

    .event-wrapper {
        margin-bottom: 10px;
        padding: 8px;
    }

    .ui.inverted.menu.navbar {
        /* display: block; */
        position: fixed;
        border-radius: 0px;
        margin-top: 0px;
        height: 50px;
        /* float: left; */
        width: 100%;
        padding: 0px;
        top: 0px;
        z-index: 999;
    }

    .ui.inverted.menu.navbar > a > .logo {
        background-image: url("./assets/logo.png");
        background-size: cover;
        margin-top: 0px;

        height: 45px;
    }

    .ui.inverted.menu.navbar > .menu-icon {
        font-size: 25px;
        position: absolute;
        right: 20px;
        top: 6px;
    }

    .mobile-links {
        /* display: none; */
        background-color: #000;
        position: fixed;
        /* top:62px;
      left:0; */
        top: 60px;
        width: 100%;
        z-index: 99;
    }

    .main {
        height: 100%;
        margin-top: 60px;
    }

    .mobile-links ul {
        padding: 0;
    }

    body {
        background-color: #000;
        margin-top: 0px;
    }

    .mobile-links ul {
        list-style: none;
        border-bottom: 1px solid #222;
    }

    .mobile-links li {
        background-color: #000;
        width: 100%;
        margin: 0;
        padding-right: 20px;
    }

    .mobile-links li div {
        color: #fff;
    }

    .mobile-links li a {
        color: #fff;
    }

    .mobile-links.active {
        display: block;
    }

    .event-venue > .suburb {
        /* margin-left:-4px; */
        float: left;
    }
    .event-venue > .suburb:before {
        content: ", ";
    }
    .desktop-view {
        display: none !important;
    }
    .mobile-view {
        display: block !important;
    }
    .header-links {
        float: none;
        margin-left: 5px;
    }
    .header-link:last-of-type {
        margin: 0;
        padding: 0;
    }
    header a img {
        width: 250px;
        padding: 0;
    }

    /* .ad {
       display:block;
       height:auto;
       min-width: 250px;
       margin-bottom:30px;
       margin-top:30px;
       width:100%;
       max-width:100%;
    }
     .ad img {
       height:auto;
       width:100%;
    } */
    ul li {
        width: 100%;
    }
    .footer {
        position: fixed !important;
        width: 100%;
        text-align: center;
    }

    // .sponsored,
    // .patreon {
    //     margin-top: 15px;
    //     margin-bottom: 15px;
    // }

    // .event-title {
    //     float: left;
    //     clear: right;
    //     width: 100%;
    //     font-size: 20px;
    // }

    // .event-time {
    //     font-size: 16px;
    // }
    // .event-time,
    // .event-venue {
    //     font-size: 16px;
    //     line-height: 1.4;
    // }

    i.fa-bars {
        color: white;
    }
    .footer {
        font-size: 11px;
    }
    .footer .footer-outer a {
        margin-right: 20px;
    }
    .footer .footer-outer a.float-right {
        float: none;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
     main section {
         column-count: 1;
    }
     .listing {
         width:95%;
    }
}
 */

main section {
    padding-top: 0px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    main section {
        column-count: 5;
        min-height: calc(100vh - 45px);
        height: calc(100vh - 45px);
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    main section {
        column-count: 5;
    }
}
/* Extra large devices (large laptops and desktops, 1500px and up) */
@media only screen and (min-width: 1600px) {
    main section {
        column-count: 8;
    }
}
/* Extra large devices (large laptops and desktops, 1500px and up) */
@media only screen and (min-width: 2000px) {
    main section {
        column-count: 10;
    }
}
/* Extra large devices (large laptops and desktops, 1500px and up) */
@media only screen and (min-width: 2400px) {
    main section {
        column-count: 12;
    }
}
/* Extra large devices (large laptops and desktops, 1500px and up) */
@media only screen and (min-width: 2800px) {
    main section {
        column-count: 14;
    }
}
/* Extra large devices (large laptops and desktops, 1500px and up) */
@media only screen and (min-width: 3200px) {
    main section {
        column-count: 16;
    }
}

.giglist-header {
    background-color: #000;
    color: white;
    font-family: "Merriweather";
    font-size: 20px;
    padding: 20px;
}

.iframe {
    position: absolute;
    top: 80 !important;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none !important;
    outline: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 999999;
}

.frame-parent {
    position: relative;
}

.ui.grid > * {
    padding-right: 0 !important;
}

.event-venue .name {
    margin-left: 1px;
}

.event-title .address {
    margin-left: 1px;
}

a {
    color: #fff !important;
}

.loader {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: black;
    z-index: 1000;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.loader div {
    transition: width 1.5s linear;
}

.modal-content {
    /* background: rgba(0, 0, 0, 0.6); */
    border-top-right-radius: 5px;
    color: white;
    position: absolute;
    font-size: 18px;
    bottom: 22px;
    left: 22px;
    font-family: Lato;
    width: 80%;
}

.modal-qr-code {
    background: rgba(0, 0, 0, 0.7);
    padding: 6px;
    position: absolute;
    right: 22px;
    bottom: 22px;
}

.modal-content-header {
    background: rgba(0, 0, 0, 0.9);
    font-family: Lato;
    line-height: 1.2;
    padding: 10px 20px 10px 20px;
    font-size: 24px;
}

.page-modal-listing {
    background-color: none;
    background-size: cover;
    width: 100%;
    min-height: 600px;
    z-index: 9999;
}

.ui.page.grid > .page-modal-listing {
    height: 100%;
    min-height: 100vw;
    width: 100%;
    background-size: cover;
}

.ui.page.grid > .page-modal-listing .modal-content {
    padding: 10px;
    bottom: 0;
    left: 0;
    padding: 30px;
    font-size: 30px;
    line-height: 1.2;
}
.ui.page.grid > .page-modal-listing .modal-content .address {
    font-size: 20px;
    line-height: 1.2;
}
.ui.page.grid > .page-modal-listing .modal-qr-code {
    right: 0;
    bottom: 0;
}

.mini-map {
    top: 274px;
    right: 160px;
    position: absolute;
    border: 6px solid black;
}

@media only screen and (max-width: 767px) {
    .mini-map {
        top: 282px;
    }
    .modal-content {
        padding: 10px;
        bottom: 30;
        left: 14;
        width: 90%;
        border-radius: 0;
    }
    .ui.modal .content {
        margin-top: -150px !important;
    }
    .modal-qr-code {
        bottom: -155px;
    }
    .modal-content-header {
        padding: 10px;
        font-size: 20px;
    }

    .event-venue,
    .event-time {
        font-size: 12px;
    }

    .event-title {
        font-size: 14px;
    }
}

.datePicker {
    visibility: hidden;
    height: 0;
    padding: 0;
    width: 0;
    position: absolute;
}

.ant-picker-date-panel {
    background-color: #000;
    color: #fff !important;
}

.ant-picker-date-panel td,
.ant-picker-date-panel th,
.ant-picker-date-panel button {
    color: #fff !important;
    font-family: carbontyperegular;
}

.ant-picker-date-panel div:hover {
    color: #000 !important;
}

.ant-picker-footer {
    display: none;
}

[id*="tippy-"] {
    display: none;
}

[id*="tippy-"] {
    display: none;
}

[id*="tippy-"] {
    display: none;
}

svg text {
    outline: none !important;
    border: none !important;
    border-radius: 0;
}

/* .ui.modal .actions {
    background-color: white;
    cursor: pointer;
    width: 32px;
    padding: 0 !important;
    margin: 0 !important;
} */
::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: #000000;
}

::-webkit-scrollbar-thumb {
    background: #ffffff !important;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
    background: #000;
}

.rcw-conversation-container > .rcw-header {
    background-color: black !important;
    color: white !important;
}

#rcw-conversation-container > div.rcw-header > h4 {
    color: white !important;
    font-family: "carbontyperegular";
}
.rcw-conversation-container > .rcw-header .rwc-title {
    color: white !important;
}

#root > main > div > div.rcw-widget-container > button {
    background-color: black !important;
    color: white !important;
}

#rcw-conversation-container > div.rcw-header {
    padding: 0;
}

#rcw-conversation-container > div.rcw-header > span {
    display: none;
}
#rcw-conversation-container > div.rcw-header > h4 {
    text-align: left;
    margin-left: 20px;
}

#messages {
    background-color: black;
}

#root
    > main
    > div
    > div.side-scroll
    > section
    > ul:nth-child(1)
    > div:nth-child(34) {
    transition: all 1.5s ease-in-out;
}

.date-control {
    padding: 0px 0 7px 0;
    font-family: courier;
    color: black;
    font-size: 15px;
    background-color: #ffffff;
    width: 100%;
    z-index: 999;
    border-bottom: 1px solid #ccc;
}

.date-control > div {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    align-items: center;
    justify-content: center;
}
.date-control p {
    padding-top: 16px;
}

.date-control button {
    border: none;
    background: none;
    cursor: pointer;
}

.date-control .next img,
.date-control .previous img {
    height: 12px;
    padding: 0 20px;
    top: 0px;
}
.date-control .previous {
    transform: rotate(180deg);
    padding-top: 0px;
}
